import { $ } from '@/lib/string'
import { sortBy, uniq } from "@/lib/array"
import { titleize } from "@/lib/string"
import classNames from "classnames"
import { Fragment, useState } from "react"
import DownloadIcon from "./icons/DownloadIcon"
import CloseIcon from './icons/CloseIcon'
import FilterIcon from './icons/FilterIcon'
import Modal from './Modal'


export default function Table (props) {
  if (props?.headings) {
    return <OldTable {...props}/>
  } else {
    return <NewTable {...props}/>
  }
}


function NewTable ({data, columns, hideRowNumbers, preventMobileLayout, compact, disableSorting, stickyHeaders}) {

  columns = columns.filter(col => !col.hidden)

  let defaultSortIndex = 0
  let reversed = false

  columns.forEach((col, i) => {
    if (col.sort) {
      defaultSortIndex = i
      reversed = col.sort === 'desc'
    }
  })

  const defaultFilters = {}
  columns.forEach(col => { defaultFilters[col.label] = [] })

  const [sortIndex, setSortIndex] = useState(defaultSortIndex)
  const [reverseSort, setReverseSort] = useState(reversed)
  const [showFilteringOptions, setShowFilteringOptions] = useState(3)
  const [filters, setFilters] = useState(defaultFilters)

  const filteredData = data.filter(row => {
    return columns.every(col => {
      const filterValue = col.csvValue ? col.csvValue(row) : col.value(row)
      return !filters[col.label]?.includes(filterValue)
    })
  })

  let sortedData = sortBy(filteredData, row => {
    if (!Array.isArray(columns)) {
      throw new Error(`Columns must be an array, but got: ${JSON.stringify(columns)}`)
    }

    columns.forEach(col => {
      if (!col.value) {
       throw new Error(`Column must have a value function, but the value property was: ${typeof col.value} on col: ${JSON.stringify(col)}`)
      }
    })

    const values = columns.map(col => col.value(row))

    // Sort by the selected column, then values by the remaining columns, to break any ties
    const sortByValues = [ values[sortIndex], ...values.filter((v, i) => i !== sortIndex) ]
    return sortByValues
  })

  if (reverseSort) {
    sortedData = sortedData.reverse()
  }


  let showTotalsRow = false
  const totals = {}

  columns.map((col) => {
    if (!col.total) return

    showTotalsRow = true

    totals[col.label] = sortedData.reduce((sum, item) => {
      const value = col.value(item)
      if (typeof value === 'number') {
        return sum + value
      } else {
        throw Error(`Cannot total column ${col.label} - value is not a number: ${JSON.stringify(value)}`)
      }
    }, 0)
  })


  function headingClickHandler (colLabel) {
    if (disableSorting) return () => {}

    return e => {
      e.preventDefault()

      const i = columns.findIndex(col => col.label === colLabel)

      if (i === sortIndex) {
        setReverseSort(!reverseSort)
      } else {
        setSortIndex(i)
        setReverseSort(false)
      }
    }
  }

  function downloadCSV(e) {
    e.preventDefault()

    const fileName = 'data.csv'
    const csvRows = []

    let headerRow = []

    columns.forEach(col => {
      let labels = col.csvLabel || col.label
      if (!Array.isArray(labels)) labels = [labels]
      labels.forEach(l => {
        headerRow.push(l)
      })
    })

    csvRows.push(headerRow)

    sortedData.forEach(row => {
      let cells = []

      columns.forEach(col => {
        let values = col.csvValue ? col.csvValue(row) : col.value(row)

        // The csvValue function can return an array of values, which will be
        // rendered as multiple columns in the CSV
        if (!Array.isArray(values)) values = [values]

        values.forEach(v => {
          // Format dates
          const isDateTime = (v instanceof Date) || (typeof v === 'string' && v.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:[.0-9]+Z$/))
          if (isDateTime) {
            v = new Date(v).toLocaleString().replace(',', '')
          }

          if (col.format === 'currency') values = values.map(v => '$' + (v / 100).toFixed(2))

          // Escape double quotes (for CSV)
          if (typeof v === 'string') v = `"${v.replace(/"/g, '""')}"`

          cells.push(v)
        })
      })

      csvRows.push(cells)
    })

    const csvText = csvRows.map(r => r.join(',')).join("\n")
    const blob = new Blob([csvText], { type: 'text/csv' })

    // Create an anchor element and dispatch a click event on it to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  if (!data.length) return <p className="missing padded">None</p>

  return <>
    <table className={classNames({hideRowNumbers, allowMobileLayout: !preventMobileLayout, compact, disableSorting, 'sticky-headers': stickyHeaders})}>

      <thead>
        <tr>
          <th className="download-button-header">
            <div className="download-button opacity-20 transition hover_opacity-100 pointer" onClick={downloadCSV} title="Download CSV" style={{marginLeft: '-0.5rem'}}>
              <DownloadIcon/>
            </div>
            <span className="cell-label subtle">Sort By...</span>
          </th>
          {
            columns.map((col, i) => {
              let total = totals[col.label]
              if (col.format === 'currency') total = $(total)

              // NOTE: Using the col.value to do sorting to respect the sort
              // order of the original values. Also, using csvValues for
              // rendering (if available) to avoid showing raw values on things
              // like dates on catalog events (e.g. "11-02" or "_evergreen").
              const renderedFilterValue = {}
              const allValues = uniq(sortBy(data, row => col.value(row)).map(row => {
                const filterValue = col.csvValue ? col.csvValue(row) : col.value(row)
                renderedFilterValue[filterValue] ||= col.render ? col.render(row) : filterValue
                return filterValue
              }))
              const filtered = filters[col.label]?.length > 0

              // NOTE: Values are ADDED to the filter object when they are
              // filtered OUT. So, it is the list of values that are NOT shown.
              function filterOut(value) {
                setFilters({
                  ...filters,
                  [col.label]: [...filters[col.label], value]
                })
              }

              function filterIn(value) {
                setFilters({
                  ...filters,
                  [col.label]: filters[col.label].filter(f => f !== value)
                })
              }

              function filterShowAll() {
                setFilters({
                  ...filters,
                  [col.label]: []
                })
              }

              function filterShowNone() {
                setFilters({
                  ...filters,
                  [col.label]: allValues
                })
              }

              return <Fragment key={col.label}>
                <th onClick={headingClickHandler(col.label)} >
                  <div
                    className={classNames("nowrap flex flex-row flex-align-center gap-1", {
                      sorted:   sortIndex === i,
                      reversed: sortIndex === i && reverseSort,
                      red:      filtered,
                    })}
                  >
                    {col.label}
                    {col.filter &&
                      <div
                        className={
                          classNames(
                            'pt-1 pointer opacity-20 hover_opacity-100',
                            {'opacity-50': filtered}
                          )
                        }
                        onClick={e => {e.stopPropagation(); setShowFilteringOptions(col.label)}}
                      >
                        <FilterIcon size={16}/>
                      </div>
                  }
                  </div>
                  <div className="total-in-header">
                    {total}
                  </div>
                </th>

                <Modal visible={showFilteringOptions === col.label} onClickOutside={() => setShowFilteringOptions(null)}>
                  <div className='bg-white shadow-xl p-2 max-height-full'>
                    <div className='p-2 flex flex-row flex-align-start spread gap-4'>
                      <div className='py-2 no-wrap bold flex flex-row gap-1'>
                        <FilterIcon/>
                        Filter: {col.label}
                      </div>
                      <button className='button is-inverted no-border hover_bg-light-gray p-2' onClick={() => setShowFilteringOptions(null)}>
                        <CloseIcon/>
                      </button>
                    </div>

                    <div className='flex-row text-sm' style={{marginTop: '-1rem'}}>
                      <span className='pl-3 gray'>
                        Select:
                      </span>
                      <a className='p-1 subtle-link' onClick={filterShowAll}>
                        All
                      </a>
                      <a className='p-1 subtle-link' onClick={filterShowNone}>
                        None
                      </a>
                    </div>

                    {
                      allValues.map((value, j) => {
                        const checked = !filters[col.label]?.includes(value)
                        return (
                          <label className='px-3 py-1 flex flex-row flex-align-start gap-2' key={j}>
                            <div style={{marginTop: 1}}>
                              <input
                                type='checkbox'
                                checked={checked}
                                onChange={() => checked ? filterOut(value) : filterIn(value)}
                              />
                            </div>
                            <span className={classNames({'opacity-50': !checked})}>
                              { renderedFilterValue[value] || <span className='gray italic text-sm'>(No Value)</span> }
                            </span>
                          </label>
                        )
                      })
                    }
                  </div>
                </Modal>
              </Fragment>
            })
          }
        </tr>
      </thead>

      <tbody>

        { showTotalsRow &&
          <tr className="total-row bg-eee bold">
            <td className={classNames('total table-row-count')}></td>

            {
              columns.map((col) => {
                if (!col.total) return <td key={col.label} className="total"/>

                let total = totals[col.label]
                if (col.format === 'currency') total = $(total)

                const zero = total === 0
                const numeric = typeof total === 'number' || (typeof total === 'string' && total.match(/^\$?[\.\d]+%?$/))
                const alignRight = (numeric && col.align !== 'left') || col.align === 'right'

                return (
                  <td key={col.label} className={classNames('total', {zero, 'align-right': alignRight})}>
                    <div className="cell-value">
                      <div className="cell-label">
                        {col.label}
                      </div>
                      {total}
                    </div>
                  </td>
                )
              })
            }
          </tr>
        }

        { sortedData.map((row, i) => {
          return (
            <tr key={row.id || i}>
              <td className="table-row-count">
                <div>
                  <span className="cell-label">Row #</span>{i + 1}
                </div>
              </td>
              {columns.map(col => {
                let value = col.value(row)
                const zero = value === 0
                const numeric = typeof value === 'number'
                const alignRight = (numeric && col.align !== 'left') || col.align === 'right'

                if (col.format === 'currency') value = $(value)

                return (
                  <td key={col.label} className={classNames('cell-value', {zero, 'align-right': alignRight})}>
                    <div className="cell-label">
                      {col.label}
                    </div>
                    <div>
                      { col.render ? col.render(row) : value }
                    </div>

                  </td>
                )}
              )}
            </tr>
          )
        })}
      </tbody>
    </table>

    <style jsx>{`
     .table-row-count {
        width: 10px;
        color: #ccc;
      }
      .hideRowNumbers .table-row-count {
        display: none;
      }
      .hideRowNumbers .download-button,
      .hideRowNumbers .download-button-header {
        display: none;
      }
      th {
        cursor: pointer;
      }
      table {
        margin-top: 1rem;
      }
      table td {
        vertical-align: middle;
      }
      .zero {
        color: #ccc;
      }
      .align-right {
        text-align: right;
      }
      .total-in-header {
        display: none;
      }
      .sorted::after {
        content: '▼';
        font-size: 0.8rem;
        margin-top: 0.2rem;
        margin-left: -0.8rem;
        position: absolute;
      }
      .reversed::after {
        content: '▲';
      }
      .disableSorting .reversed::after,
      .disableSorting .sorted::after {
        content: '';
      }
      .disableSorting th {
        cursor: default;
      }
      .cell-label {
        display: none;
        font-weight: normal;
        color: #AAA;
      }
      @media only screen and (min-width: 768px) {
        .sticky-headers thead {
          position: sticky;
          top: 0;
          z-index: 1;
          background: white;
          box-shadow: 0 0 2px -1px rgba(0,0,0,0.3);
          min-width: 100%;
        }
      }

      .compact th,  .compact td {
        padding: 0.4rem 0.5rem;
      }

      @media only screen and (min-width: 767px) {
        .compact th {
          padding-top: 0.15rem;
          padding-bottom: 0.15rem;
        }
        .compact td {
          padding-left: 0.15rem;
          padding-right: 0.15rem;
        }
      }
      @media only screen and (min-width: 900px) {
        .compact th {
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
        }
        .compact td {
          padding-left: 0.4rem;
          padding-right: 0.4rem;
        }
      }
      @media only screen and (max-width: 767px) {
        .allowMobileLayout tr {
          display: flex;
          flex-direction: column;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
          margin: 1rem 0;
        }
        .allowMobileLayout .download-button-header {
          display: block;
        }
        .allowMobileLayout .total-row {
          display: none;
        }
        .allowMobileLayout .total-in-header {
          display: block;
        }
        .allowMobileLayout :global(.fit-content) {
          width: 100% !important;
        }
        table.allowMobileLayout  {
          width: 100% !important;
        }
        table.allowMobileLayout  th {
          border-bottom: 1px dashed #ccc;
        }
        table.allowMobileLayout  td, table.allowMobileLayout th {
          border-style: dashed;
          width: 100%;
          display: grid;
          grid-template-columns: minmax(max-content, 33%) auto;
          overflow-wrap: anywhere; /* to prevent long emails from breaking layout */
        }
        .allowMobileLayout tr th:first-child, .allowMobileLayout tr td:first-child {
          background-color: #f3f3f3;
        }
        table.allowMobileLayout th:last-child, table.allowMobileLayout td:last-child {
          border-style: none;
        }
        .allowMobileLayout .table-row-count {
          color: #aaa;
        }
        .allowMobileLayout .cell-label {
          display: unset;
          text-align: left;
        }
        .allowMobileLayout .download-button {
          display: none;
        }
        .allowMobileLayout .cell-value {
          text-align: left !important;
        }
      }
    `}</style>
  </>
}


// This was the old component, where we had to pass in headings, row, and values
function OldTable ({data, headings, row, values, reverse, defaultSortKey, compact, stickyHeaders, hideRowNumbers, disableSorting, preventMobileLayout}) {
  let defaultSortIndex = defaultSortKey ? headings.findIndex(h => h.key === defaultSortKey) : 0
  if (defaultSortIndex < 0) defaultSortIndex = 0

  const [sortIndex, setSortIndex] = useState(defaultSortIndex)
  const [reverseSort, setReverseSort] = useState(reverse || false)

  headings = headings.filter(h => !h.hidden)

  const sortKey = headings[sortIndex]?.key
  let sortedData = data

  const totals = {}

  headings.map((hd) => {
    if (!hd.total) return

    totals[hd.key] = sortedData.reduce((sum, item) => {
      const value = values(item)[hd.key]
      if (typeof value === 'number') {
        return sum + value
      } else {
        throw Error(`Cannot total column ${hd.key} - value is not a number: ${JSON.stringify(value)}`)
      }
    }, 0)
  })


  function handleHeadingClick (i) {
    if (disableSorting) return
    if (i === sortIndex) {
      setReverseSort(!reverseSort)
    } else {
      setSortIndex(i)
      setReverseSort(false)
    }
  }

  function downloadCSV(e) {
    e.preventDefault()

    const fileName = 'data.csv'
    const data = headings.map(h => h.label || titleize(h.key)).join(',') + '\n' +
      sortedData.map(values).map(r => {
        return headings.map(h => {
          let v = r[h.key]
          const isDateTime = (v instanceof Date) || (typeof v === 'string' && v.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:[.0-9]+Z$/))
          if (isDateTime) {
            v = new Date(v).toLocaleString().replace(',', '')
          }

          if (typeof v === 'string') {
            return `"${v.replace(/"/g, '""')}"`
          } else {
            return v
          }
        }).join(',')
      }).join("\n")
    const blob = new Blob([data], { type: 'text/csv' })

    // Create an anchor element and dispatch a click event on it to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  if (sortKey) {
    sortedData = sortBy(data, item => {
      const sortValues = values(item)

      // Sort by the selected column
      const sortByValues = [
       sortValues[sortKey],
      ]

      // Then values by the remaining columns, to break any ties
      headings.forEach((hd, i) => {
        if (i === sortIndex) return
        let val = sortValues[hd.key]
        sortByValues.push(val)
      })

      return sortByValues
    })

    if (reverseSort) {
      sortedData = sortedData.slice(0).reverse()
    }
  }

  if (!data.length) return <p className="missing padded">None</p>

  return <>
    <table className={classNames({hideRowNumbers, allowMobileLayout: !preventMobileLayout, compact, disableSorting, 'sticky-headers': stickyHeaders})}>

      <thead>
        <tr>
          <th className="download-button-header">
            <div className="download-button opacity-20 transition hover_opacity-100 pointer" onClick={downloadCSV} title="Download CSV" style={{marginLeft: '-0.5rem'}}>
              <DownloadIcon/>
            </div>
            <span className="cell-label subtle">Sort By...</span>
          </th>
          {headings.map((hd, i) => (
            <th
              key={hd.key}
              onClick={() => handleHeadingClick(i)}
            >
              <div
                className={classNames("nowrap", {
                  sorted: hd.key === sortKey,
                  reversed: hd.key === sortKey && reverseSort,
                })}
              >
                {hd.label || titleize(hd.key)}
              </div>
              <div className="total-in-header">
                {(hd.formatter || (v => v))(totals[hd.key])}
              </div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>

        { headings.find (h => h.total) &&
          <tr className="total-row bg-eee bold">
            <td className={classNames('total table-row-count')}></td>

            {
              headings.map((hd, i) => {
                if (!hd.total) return <td key={hd.key} className="total"/>

                const total = totals[hd.key]
                const formatter = hd.formatter || (v => v)
                const zero = total === 0
                const numeric = typeof total === 'number' || (typeof total === 'string' && total.match(/^\$?[\.\d]+%?$/))

                return (
                  <td key={hd.key} className={classNames('total', {zero, numeric})}>
                    <div className="cell-value">
                      <div className="cell-label">
                        {hd.label || titleize(hd.key)}
                      </div>
                      {formatter(total)}
                    </div>
                  </td>
                )
              })
            }
          </tr>
        }

        {sortedData.map((item, i) => {
          const cells = row(item)
          return (
            <tr key={item.id || i}>
              <td className="table-row-count">
                <div>
                  <span className="cell-label">Row #</span>{i + 1}
                </div>
              </td>
              {headings.map(hd => {
                const value = cells[hd.key]
                const zero = value === 0
                const numeric = typeof value === 'number' || (typeof value === 'string' && value.match(/^\$?[\.\d]+%?$/))

                return (
                  <td key={hd.key} className={classNames('cell-value', {zero, numeric})}>
                    <div className="cell-label">{hd.label || titleize(hd.key)}</div>
                    {value}
                  </td>
                )}
              )}
            </tr>
          )
        })}
      </tbody>
    </table>

    <style jsx>{`
     .table-row-count {
        width: 10px;
        color: #ccc;
      }
      .hideRowNumbers .table-row-count {
        display: none;
      }
      .hideRowNumbers .download-button,
      .hideRowNumbers .download-button-header {
        display: none;
      }
      th {
        cursor: pointer;
      }
      table {
        margin-top: 1rem;
      }
      table td {
        vertical-align: middle;
      }
      .zero {
        color: #ccc;
      }
      .numeric {
        text-align: right;
      }
      .total-in-header {
        display: none;
      }
      .sorted::after {
        content: '▼';
        font-size: 0.8rem;
        margin-top: 0.2rem;
        margin-left: 0.2rem;
        position: absolute;
      }
      .reversed::after {
        content: '▲';
      }
      .disableSorting .reversed::after,
      .disableSorting .sorted::after {
        content: '';
      }
      .disableSorting th {
        cursor: default;
      }
      .cell-label {
        display: none;
        font-weight: normal;
        color: #AAA;
      }
      @media only screen and (min-width: 768px) {
        .sticky-headers thead {
          position: sticky;
          top: 0;
          z-index: 1;
          background: white;
          box-shadow: 0 0 2px -1px rgba(0,0,0,0.3);
          min-width: 100%;
          height: 9rem;
        }
      }

      .compact th,  .compact td {
        padding: 0.4rem 0.5rem;
      }

      @media only screen and (min-width: 767px) {
        .compact th {
          padding-top: 0.15rem;
          padding-bottom: 0.15rem;
        }
        .compact td {
          padding-left: 0.15rem;
          padding-right: 0.15rem;
        }
      }
      @media only screen and (min-width: 900px) {
        .compact th {
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
        }
        .compact td {
          padding-left: 0.4rem;
          padding-right: 0.4rem;
        }
      }
      @media only screen and (max-width: 767px) {
        .allowMobileLayout tr {
          display: flex;
          flex-direction: column;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
          margin: 1rem 0;
        }
        .allowMobileLayout .download-button-header {
          display: block;
        }
        .allowMobileLayout .total-row {
          display: none;
        }
        .allowMobileLayout .total-in-header {
          display: block;
        }
        .allowMobileLayout :global(.fit-content) {
          width: 100% !important;
        }
        table.allowMobileLayout  {
          width: 100% !important;
        }
        table.allowMobileLayout  th {
          border-bottom: 1px dashed #ccc;
        }
        table.allowMobileLayout  td, table.allowMobileLayout th {
          border-style: dashed;
          width: 100%;
          display: grid;
          grid-template-columns: minmax(max-content, 33%) auto;
          overflow-wrap: anywhere; /* to prevent long emails from breaking layout */
        }
        .allowMobileLayout tr th:first-child, .allowMobileLayout tr td:first-child {
          background-color: #f3f3f3;
        }
        table.allowMobileLayout th:last-child, table.allowMobileLayout td:last-child {
          border-style: none;
        }
        .allowMobileLayout .table-row-count {
          color: #aaa;
        }
        .allowMobileLayout .cell-label {
          display: unset;
          text-align: left;
        }
        .allowMobileLayout .download-button {
          display: none;
        }
        .allowMobileLayout .cell-value {
          text-align: left !important;
        }
      }
    `}</style>
  </>
}
